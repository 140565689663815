import { Component, Event, EventEmitter, Host, Listen, Prop, State, h } from '@stencil/core';
import { currentId } from '../../utils/id';
import { PTZDrawerConfig } from './types/ptz-drawer.enums';
import { PTZDrawerTypes } from './types/ptz-drawer.types';

@Component({
  tag: `ptz-drawer`,
  styleUrl: 'ptz-drawer.scss',
  shadow: false,
})

export class PTZDrawer {
  /** Define se a gaveta terá borda arredondada */
  @Prop() rounded?: boolean = false;

  /** Define a posição da gaveta */
  @Prop() orientation?: PTZDrawerTypes.Orientation = PTZDrawerConfig.Orientation.Right;

  /** Estado de carregando no botão */
  @Prop() loading?: boolean = false;

  /** Abrir ou não a gaveta */
  @Prop({ mutable: true }) open: boolean = false;

  /** Adicionar altura dinâmica no conteúdo da gaveta */
  @Prop() dynamicContentHeight?: boolean = true;

  /** Preenche id da gaveta */
  @Prop() drawerId?: string

  /** Evento para saber se o Drawer está aberto ou fechado */
  @Event({
    eventName: 'toggleDrawer',
    composed: true,
  }) toggleDrawer: EventEmitter<boolean>;

  @State() hasSwapAnimation: boolean = false;

  @State() show: boolean = false;

  @Listen('slotDrawerChange', { target: 'window' })
  handleSlotChange(event: CustomEvent<boolean>) {
    this.hasSwapAnimation = event.detail;

    setTimeout(() => {
      this.hasSwapAnimation = false;
    }, 600);
  }

  private currentDrawerId = currentId(this.drawerId);

  private setModalContentHeight(id: string) {
    const modalContent = document.querySelector(`#${id} .ptz-drawer-modal-content`) as HTMLElement;
    modalContent.style.height = null

    if (!this.dynamicContentHeight || this.loading) return;

    const modalContentHeight = modalContent.offsetHeight || 0;
    const modalHeaderHeight = (document.querySelector(`#${id} .ptz-drawer-modal-header`) as HTMLElement).offsetHeight || 0;
    const modalFooterHeight = (document.querySelector(`#${id} .ptz-drawer-modal-footer`) as HTMLElement).offsetHeight || 0;
    let contentHeight = window.innerHeight - modalHeaderHeight - modalFooterHeight;

    if (this.orientation === PTZDrawerConfig.Orientation.Bottom) {
      const modalElement = (document.querySelector(`#${id}.ptz-drawer-modal`) as HTMLElement);
      const topBarHeight = 20;
      const modalElementHeight = modalHeaderHeight + modalFooterHeight + modalContentHeight + topBarHeight;

      modalElement.style.height = `${modalElementHeight}px`;
      contentHeight = modalContentHeight > contentHeight ? (contentHeight - 67) : modalContentHeight;
    }

    if (this.orientation === PTZDrawerConfig.Orientation.Left && modalFooterHeight === 0) {
      const footerHeight = 10;
      contentHeight = contentHeight - footerHeight;
    }

    modalContent.style.height = `${contentHeight}px`;
  }

  @Listen('resize', { target: 'window' })
  _onModalContentHeightChange() {
    window.addEventListener('resize', () => this.setModalContentHeight(this.currentDrawerId));
  }

  private toggleDrawerHandler() {
    this.open = !this.open;
    this.toggleDrawer.emit(this.open);
  }

  componentDidRender() {
    this.setModalContentHeight(this.currentDrawerId);
  }

  componentDidLoad() {
    this.show = true;
  }

  render() {
    const classOrientation = `ptz-drawer-${this.orientation}`;

    const classDrawer = {
      "ptz-drawer": true,
      "ptz-drawer-open": this.open,
      "ptz-drawer-close": !this.open,
      "ptz-drawer-loading": this.loading,
      [`${classOrientation}`]: true,
      [`${classOrientation}-open`]: this.open,
      [`${classOrientation}-close`]: !this.open,
      [`${classOrientation}-rounded`]: this.rounded,
    }

    const handleToggleDrawer = () => {
      this.toggleDrawerHandler();
    }

    return (
      <Host class={{ 'show': this.show }}>
        <div class={classDrawer}>
          <div class="ptz-drawer-overlay" onClick={handleToggleDrawer}></div>
          <div id={this.currentDrawerId} class="ptz-drawer-modal">
            <div class={{"ptz-drawer-modal-overlay": true, "ptz-drawer-modal-overlay-swapping": this.hasSwapAnimation }}></div>
            {this.loading ? (
              <div class="ptz-drawer-loading-background">
                <ptz-spinner size="lg" appearance="light" />
              </div>
            ) : null}
            <div class={{ "ptz-drawer-modal-header": true, 'swapping': this.hasSwapAnimation }}>
              <span class="ptz-drawer-modal-header-title">
                <slot name="drawer-header-title" />
              </span>
              <button aria-label="Botão de fechar" class="ptz-drawer-modal-header-button-close" onClick={handleToggleDrawer}>
                <slot name="drawer-header-close" />
              </button>
            </div>
            <div>
              <div class={{ "ptz-drawer-modal-content": true, 'swapping': this.hasSwapAnimation }} tabindex="0">
                <slot name="drawer-content" />
              </div>
              <div class={{ "ptz-drawer-modal-footer": true, 'swapping': this.hasSwapAnimation }}>
                <slot name="drawer-footer" />
              </div>
            </div>
          </div>
        </div>
      </Host>
    );
  }
}
