$margin-top-modal-bottom: $petz-spacing-2xl;

@keyframes swapping-overlay {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes swapping {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5vh);
  }
  100% {
    transform: translateY(0);
  }
}

ptz-drawer {
  &.show {
    display: block;
  }

  .ptz-drawer {
    overflow: hidden;

    &-loading {
      &-background {
        height: 100%;
        width: 100%;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.8);
      }

      .ptz-drawer-modal {
        p, span {
          user-select: none;
        }
      }
    }

    &-modal {
      z-index: 998;
      position: fixed;
      overflow: hidden;
      background-color: $petz-color-neutral-white;

      &-overlay {
        opacity: 0;
        height: 100%;
        width: 100%;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        pointer-events: none;
        background-color: $petz-color-neutral-white;

        &-swapping {
          animation: swapping-overlay 0.6s forwards;
          pointer-events: all;
        }
      }

      & .swapping {
        animation: swapping 0.6s forwards;
        pointer-events: all;
      }

      &-header {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        border-bottom: $petz-border-sm solid $petz-color-neutral-light;
        padding: $petz-spacing-xs 1.563rem 0;

        p {
          margin: 1.25rem 0;
        }

        &-title {
          text-align: start;
          font-weight: $petz-font-weight-bold;
          font-size: $petz-font-size-md;
          line-height: $petz-font-lineheight-lg;
          color: $petz-color-neutral-darker-accent;
        }

        &-button-close {
          border: $petz-border-none;
          cursor: pointer;
          background: none;
          padding-right: 0;
          padding-left: $petz-spacing-sm;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        overflow-y: auto;

        p {
          margin: 0;
        }
      }

      &-footer {
        bottom: 0;
        width: 100%;
        position: absolute;
        background-color: $petz-color-neutral-white;
      }
    }

    &-overlay {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: 998;
      position: fixed;
      visibility: hidden;
      pointer-events: auto;
      background-color: #000000;
      transition: visibility 0s linear 300ms, opacity 300ms;
    }

    &-open {
      .ptz-drawer-overlay {
        opacity: 64%;
        visibility: visible;
        transition-delay: 0s;
      }
    }

    &-close {
      .ptz-drawer-overlay {
        opacity: 0;
        visibility: hidden;
        transition-delay: 300ms;
      }
    }

    &-right, &-left {
      .ptz-drawer-modal {
        top: 0;
        bottom: 0;
        width: 30rem;
        height: 100%;
        transition: transform 300ms ease-out;
      }

      &-open {
        .ptz-drawer-modal {
          transform: none;
        }
      }
    }

    &-right {
      .ptz-drawer-modal {
        right: 0;
      }

      &-rounded {
        .ptz-drawer-loading-background,
        .ptz-drawer-modal,
        .ptz-drawer-modal-footer {
          border-radius: $petz-spacing-md $petz-border-none $petz-border-none $petz-spacing-md;
        }
      }

      &-close {
        .ptz-drawer-modal {
          transform: translateX(100%);
        }
      }
    }

    &-left {
      .ptz-drawer-modal {
        left: 0;
      }

      &-rounded {
        .ptz-drawer-loading-background,
        .ptz-drawer-modal,
        .ptz-drawer-modal-footer {
          border-radius: $petz-border-none $petz-spacing-md $petz-spacing-md $petz-border-none;
        }
      }

      &-close {
        .ptz-drawer-modal {
          transform: translateX(-100%);
        }
      }
    }

    &-bottom {
      .ptz-drawer-modal {
        left: 0;
        right: 0;
        bottom: -62.5rem;
        width: 100vw;
        box-sizing: border-box;
        height: calc(100% - $margin-top-modal-bottom);
        max-height: calc(100% - $margin-top-modal-bottom);

        transition: bottom 300ms ease-out;

      &-header {
            margin: 0 $petz-spacing-md;
            padding: $petz-spacing-xs 0 0;
          }

          &::before {
            content: '';
            display: block;
            width: 3.75rem;
            height: $petz-spacing-3xs;
            background: $petz-color-neutral-mid;
            border-radius: $petz-rounded-max;
            margin: $petz-spacing-sm auto 0;
          }
      }

      &-rounded {
        .ptz-drawer-loading-background,
        .ptz-drawer-modal {
          border-radius: $petz-spacing-md $petz-spacing-md 0 0;
        }
      }

      &-open {
        .ptz-drawer-modal {
          bottom: 0;
        }
      }

      &-close {
        bottom: -62.5rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  ptz-drawer {
    .ptz-drawer {
      &-modal-header-title {
        font-size: $petz-font-size-sm;
      }

      &-right,
      &-left {
        .ptz-drawer-modal {
          width: 20.75rem;
        }
      }
    }
  }
}
