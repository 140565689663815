ptz-button {
  .ptz-btn {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px 24px;
    border-radius: $petz-rounded-xs;
    border-style: none;
    border-style: solid;
    font-weight: $petz-font-weight-bold;
    transition: ease-in-out 0.2s;
    cursor: pointer;

    &-span {
      display: flex;
      flex-direction: column;

      &-strong {
        margin: 0;
        text-align: start;
        font-size: $petz-font-size-2xs;
      }

      &-sub {
        font-weight: $petz-font-weight-regular;
        text-align: start;
        font-size: $petz-font-size-3xs;
      }
    }

    &-light-primary {
      border-width: $petz-border-none;
      background-color: $petz-color-primary-brand;
      color: $petz-color-neutral-white;

      &:hover {
        background-color: $petz-color-primary-light;
      }
    }

    &-light-lighter,
    &-dark-lighter,
    &-color-lighter {
      border-width: $petz-border-none;
      background-color: $petz-color-secondary-light;
      color: $petz-color-primary-brand;

      &:hover {
        background-color: $petz-color-secondary-brand;
      }
    }

    &-dark-primary,
    &-color-primary {
      border-width: $petz-border-none;
      background-color: $petz-color-neutral-white;
      color: $petz-color-neutral-black;

      &:hover {
        background-color: $petz-color-neutral-lighter-accent;
      }
    }

    &-light-secondary {
      background-color: transparent;
      border-width: $petz-border-md;
      border-color: $petz-color-neutral-darker-accent;
      color: $petz-color-neutral-darker-accent;

      &:hover {
        border-color: $petz-color-primary-dark;
        color: $petz-color-primary-dark;
      }
    }

    &-dark-secondary,
    &-color-secondary {
      background-color: transparent;
      border-width: $petz-border-md;
      border-color: $petz-color-neutral-white;
      color: $petz-color-neutral-white;

      &:hover {
        border-color: $petz-color-neutral-darker-accent;
        color: $petz-color-neutral-darker-accent;
      }
    }

    &-light-ghost {
      background-color: transparent;
      border-width: transparent;
      border-color: transparent;
      color: $petz-color-primary-brand;

      &:hover {
        color: $petz-color-primary-light;
      }
    }

    &-dark-ghost,
    &-color-ghost {
      background-color: transparent;
      border-width: transparent;
      border-color: transparent;
      color: $petz-color-neutral-white;

      &:hover {
        color: $petz-color-neutral-darker-accent;
      }
    }

    &[disabled] {
      cursor: not-allowed;

      .ptz-btn {
        &-span,
        &-strong,
        &-sub,
        &-icon {
          pointer-events: none;
        }
      }
    }

    &-light-primary,
    &-light-secondary,
    &-color-primary {
      &[disabled] {
        background-color: $petz-color-neutral-light;
        color: $petz-color-neutral-dark;
        border: $petz-border-none;
      }
    }

    &-dark-primary,
    &-dark-secondary {
      &[disabled] {
        background-color: $petz-color-neutral-dark;
        color: $petz-color-neutral-light;
        border: $petz-border-none;
      }
    }

    &-light-ghost,
    &-dark-ghost {
      &[disabled] {
        color: $petz-color-neutral-dark;
      }
    }

    &-color-ghost {
      &[disabled] {
        color: $petz-color-neutral-mid;
      }
    }

    &-lg {
      height: $petz-spacing-3xl;
    }

    &-md {
      height: $petz-spacing-2xl;
    }

    &-sm {
      height: $petz-spacing-xl;
    }

    &-xs {
      height: $petz-spacing-lg;
    }

    &-lg-full-width {
      height: $petz-spacing-3xl;
      width: 100%;
    }

    &-md-full-width {
      height: $petz-spacing-2xl;
      width: 100%;
    }

    &-sm-full-width {
      height: $petz-spacing-xl;
      width: 100%;
    }

    &-xs-full-width {
      height: $petz-spacing-lg;
      width: 100%;
    }

    .ptz-btn-icon {
      i {
        display: flex;
      }
    }

    &-right {
      .ptz-btn-icon {
        margin-left: $petz-spacing-2xs;
      }
    }

    &-left {
      .ptz-btn-icon {
        margin-right: $petz-spacing-2xs;
      }
    }

    &-justified-left,
    &-justified-right {
      justify-content: space-between;
      padding-left: $petz-spacing-sm;
      padding-right: $petz-spacing-sm;
    }

    &-justified-left {
      &-strong,
      &-sub {
        text-align: right;
      }

      .ptz-btn-icon {
        margin-right: $petz-spacing-3xl;
      }
    }

    &-justified-right {
      &-strong,
      &-sub {
        text-align: left;
      }

      .ptz-btn-icon {
        margin-left: $petz-spacing-3xl;
      }
    }

    &-loading {
      cursor: wait;

      .ptz-btn-loader {
        margin: $petz-spacing-3xs 0 0 $petz-spacing-3xs;
        display: inline-block;

        &-label {
          margin: 0;
        }
      }
    }
  }

  .srs-btn {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px 24px;
    border-radius: $seres-rounded-xs;
    border-style: none;
    border-style: solid;
    font-weight: $seres-font-weight-bold;
    transition: ease-in-out 0.2s;
    cursor: pointer;

    &-span {
      display: flex;
      flex-direction: column;

      &-strong {
        margin: 0;
        text-align: start;
        font-size: $seres-font-size-2xs;
      }

      &-sub {
        font-weight: $seres-font-weight-regular;
        text-align: start;
        font-size: $seres-font-size-3xs;
      }
    }

    &-light-primary {
      border-width: $seres-border-none;
      background-color: $seres-color-primary-brand;
      color: $seres-color-neutral-white;

      &:hover {
        background-color: $seres-color-primary-light;
      }
    }

    &-light-lighter,
    &-dark-lighter,
    &-color-lighter {
      border-width: $seres-border-none;
      background-color: $seres-color-secondary-light;
      color: $seres-color-primary-brand;

      &:hover {
        background-color: $seres-color-secondary-brand;
      }
    }

    &-dark-primary,
    &-color-primary {
      border-width: $seres-border-none;
      background-color: $seres-color-neutral-white;
      color: $seres-color-neutral-black;

      &:hover {
        background-color: $seres-color-neutral-lighter-accent;
      }
    }

    &-light-secondary {
      background-color: transparent;
      border-width: $seres-border-md;
      border-color: $seres-color-neutral-darker-accent;
      color: $seres-color-neutral-darker-accent;

      &:hover {
        border-color: $seres-color-primary-dark;
        color: $seres-color-primary-dark;
      }
    }

    &-dark-secondary,
    &-color-secondary {
      background-color: transparent;
      border-width: $seres-border-md;
      border-color: $seres-color-neutral-white;
      color: $seres-color-neutral-white;

      &:hover {
        border-color: $seres-color-neutral-darker-accent;
        color: $seres-color-neutral-darker-accent;
      }
    }

    &-light-ghost {
      background-color: transparent;
      border-width: transparent;
      border-color: transparent;
      color: $seres-color-primary-brand;

      &:hover {
        color: $seres-color-primary-light;
      }
    }

    &-dark-ghost,
    &-color-ghost {
      background-color: transparent;
      border-width: transparent;
      border-color: transparent;
      color: $seres-color-neutral-white;

      &:hover {
        color: $seres-color-neutral-darker-accent;
      }
    }

    &[disabled] {
      cursor: not-allowed;

      .srs-btn {
        &-span,
        &-strong,
        &-sub,
        &-icon {
          pointer-events: none;
        }
      }
    }

    &-light-primary,
    &-light-secondary,
    &-color-primary {
      &[disabled] {
        background-color: $seres-color-neutral-light;
        color: $seres-color-neutral-dark;
        border: $seres-border-none;
      }
    }

    &-dark-primary,
    &-dark-secondary {
      &[disabled] {
        background-color: $seres-color-neutral-dark;
        color: $seres-color-neutral-light;
        border: $seres-border-none;
      }
    }

    &-light-ghost,
    &-dark-ghost {
      &[disabled] {
        color: $seres-color-neutral-dark;
      }
    }

    &-color-ghost {
      &[disabled] {
        color: $seres-color-neutral-mid;
      }
    }

    &-lg {
      height: $seres-spacing-3xl;
    }

    &-md {
      height: $seres-spacing-2xl;
    }

    &-sm {
      height: $seres-spacing-xl;
    }

    &-xs {
      height: $seres-spacing-lg;
    }

    &-lg-full-width {
      height: $seres-spacing-3xl;
      width: 100%;
    }

    &-md-full-width {
      height: $seres-spacing-2xl;
      width: 100%;
    }

    &-sm-full-width {
      height: $seres-spacing-xl;
      width: 100%;
    }

    &-xs-full-width {
      height: $seres-spacing-lg;
      width: 100%;
    }

    .srs-btn-icon {
      i {
        display: flex;
      }
    }

    &-right {
      .srs-btn-icon {
        margin-left: $seres-spacing-2xs;
      }
    }

    &-left {
      .srs-btn-icon {
        margin-right: $seres-spacing-2xs;
      }
    }

    &-justified-left,
    &-justified-right {
      justify-content: space-between;
      padding-left: $seres-spacing-sm;
      padding-right: $seres-spacing-sm;
    }

    &-justified-left {
      &-strong,
      &-sub {
        text-align: right;
      }

      .srs-btn-icon {
        margin-right: $seres-spacing-3xl;
      }
    }

    &-justified-right {
      &-strong,
      &-sub {
        text-align: left;
      }

      .srs-btn-icon {
        margin-left: $seres-spacing-3xl;
      }
    }

    &-loading {
      cursor: wait;

      .srs-btn-loader {
        margin: $seres-spacing-3xs 0 0 $seres-spacing-3xs;
        display: inline-block;

        &-label {
          margin: 0;
        }
      }
    }
  }
}
