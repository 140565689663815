import { Component, h, Host, Prop } from '@stencil/core';
import { valueToLowerCaseForDataTestId } from '../../utils/utils';
import { PTZBrandConfig } from '../ptz-brand/types/ptz-brand.enums';
import { PTZBrandTypes } from '../ptz-brand/types/ptz-brand.types';
import { PTZIconTypes } from '../ptz-icon/types/ptz-icon.types';
import { PTZButtonConfig } from './types/ptz-button.enums';
import { PTZButtonTypes } from './types/ptz-button.types';

@Component({
  tag: 'ptz-button',
  styleUrl: 'ptz-button.scss',
  shadow: false,
})
export class PTZButton {
  /** Cor onde o botão é aplicado */
  @Prop() appearance?: PTZButtonTypes.Appearance = PTZButtonConfig.Appearance.Light;

  /** Define se vai utilizar estilos da Petz ou de outra marca */
  @Prop() brand?: PTZBrandTypes.Name = PTZBrandConfig.Name.Petz;

  /** Aria label para o botão */
  @Prop() buttonAriaLabel?: string;

  /** Desabilita o botão */
  @Prop() disabled: boolean = false;

  /** Alinhamento do ícone no botão */
  @Prop() iconAlign?: PTZIconTypes.Align = 'left';

  /** Botão justificado com ícone */
  @Prop() iconJustified?: boolean = false;

  /** Nome do ícone no botão */
  @Prop() iconName?: PTZIconTypes.Name;

  /** Variante do ícone no botão */
  @Prop() iconVariant?: PTZIconTypes.Variant;

  /** Variedade do botão */
  @Prop() kind?: PTZButtonTypes.Kind = PTZButtonConfig.Kind.Primary;

  /** Texto do botão */
  @Prop() label?: string;

  /** Estado de carregando no botão */
  @Prop() loading?: boolean = false;

  /** Tamanho do botão */
  @Prop() size?: PTZButtonTypes.Size = PTZButtonConfig.Size.Large;

  /** Subtexto do botão */
  @Prop() supportLabel?: string;

  /** Tipo do HTML */
  @Prop() type?: PTZButtonTypes.Type = PTZButtonConfig.Type.Button;

  /** Tabindex do HTML */
  @Prop() buttonTabindex?: number;

  /** Estado de renderização */
  @Prop() skeleton: boolean = false;

  render() {
    const classButton = `
      ${this.brand}-btn
      ${this.brand}-btn-${this.appearance}-${this.kind}
      ${this.brand}-btn-${this.size}
      ${this.iconAlign && !this.iconJustified ? `${this.brand}-btn-${this.iconAlign}` : ''}
      ${!this.disabled && this.loading ? `${this.brand}-btn-loading` : ''}
      ${this.iconJustified ? `${this.brand}-btn-justified-${this.iconAlign}` : ''}
    `;

    const classSpinner = {
      [`${this.brand}-btn-loader`]: true,
      [`${this.brand}-btn-loader-label`]: !this.label,
    };

    const iconLeft = this.label && this.iconName && this.iconVariant && !this.loading && this.iconAlign === PTZButtonConfig.IconAlign.Left && (
      <ptz-icon class={`${this.brand}-btn-icon`} name={this.iconName} variant={this.iconVariant} size="lg"></ptz-icon>
    );

    const iconRight = this.label && this.iconName && this.iconVariant && !this.loading && this.iconAlign === PTZButtonConfig.IconAlign.Right && (
      <ptz-icon class={`${this.brand}-btn-icon`} name={this.iconName} variant={this.iconVariant} size="lg"></ptz-icon>
    );

    const onlyIcon = !this.label && !this.supportLabel && !this.iconAlign && (
      <ptz-icon class={`${this.brand}-btn-icon`} name={this.iconName} variant={this.iconVariant} size="xl"></ptz-icon>
    );

    const skeletonSize = {
      'xs': { w: 151, h: 32 },
      'sm': { w: 151, h: 40 },
      'md': { w: 151, h: 48 },
      'lg': { w: 151, h: 56 },
      'xs-full-width': { w: 938, h: 40 },
      'sm-full-width': { w: 938, h: 32 },
      'md-full-width': { w: 938, h: 48 },
      'lg-full-width': { w: 938, h: 56 },
    };

    return (
      <Host>
        {this.skeleton ? (
          <ptz-skeleton width={skeletonSize[this.size].w} height={skeletonSize[this.size].h} rounded="sm"></ptz-skeleton>
        ) : (
          <button
            data-testid={`${this.brand}-button-${valueToLowerCaseForDataTestId(this.label)}`}
            tabIndex={this.buttonTabindex}
            type={this.type}
            role="button"
            aria-label={this.buttonAriaLabel}
            disabled={this.disabled}
            class={classButton}
          >
            {iconLeft}
            {this.label && (
              <span data-testid={`${this.brand}-button-${valueToLowerCaseForDataTestId(this.label)}-span`} class={`${this.brand}-btn-span`}>
                <strong class={`${this.brand}-btn-strong`}>{this.label}</strong>
                <sub class={`${this.brand}-btn-sub`}>{this.supportLabel}</sub>
              </span>
            )}
            <slot />
            {onlyIcon}
            {iconRight}
            {!this.disabled && this.loading && (
              <ptz-spinner
                size="md"
                appearance={this.kind === PTZButtonConfig.Kind.Primary || this.kind === PTZButtonConfig.Kind.Lighter ? 'color' : 'light'}
                class={classSpinner}
              ></ptz-spinner>
            )}
          </button>
        )}
      </Host>
    );
  }
}
